<template>
  <v-app>
    <div>
      <!-- Header Alert -->
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text">
          Daftar <b>Pendaftaran Seminar Pra Skripsi</b>
        </div>
        <b-button
          squared
          @click="$router.push('/pra-essay-seminar-registrations/add')"
          v-if="btnAccess || currentUser.role_id == 2"
          variant="primary"
          >Tambah</b-button
        >
      </b-alert>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div>
                <!-- Filter -->
                <div class="row justify-content-end mt-n3">
                  <!-- <div class="col-md-8">
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('days')"
                    v-bind:class="{
                      'btn-info': btnActive.days,
                      'btn-outline-info': btnActive.days == false,
                    }"
                  >
                    Hari Ini
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('month')"
                    v-bind:class="{
                      'btn-info': btnActive.month,
                      'btn-outline-info': btnActive.month == false,
                    }"
                  >
                    Bulan Ini
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('lastMonth')"
                    v-bind:class="{
                      'btn-info': btnActive.lastMonth,
                      'btn-outline-info': btnActive.lastMonth == false,
                    }"
                  >
                    Bulan Kemarin
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    @click="btnActiveOnClick('year')"
                    v-bind:class="{
                      'btn-info': btnActive.year,
                      'btn-outline-info': btnActive.year == false,
                    }"
                  >
                    Tahun Ini
                  </button>
                  <button
                    class="btn mx-1 btn-timeseries"
                    v-bind:class="{
                      'btn-info': btnActive.period,
                      'btn-outline-info': btnActive.period == false,
                    }"
                    @click="btnPeriodeOnClick"
                  >
                    Periode
                  </button>
                </div> -->
                  <div class="col-md-3">
                    <treeselect
                      v-model="filter.status_id"
                      :multiple="false"
                      placeholder="Saring Status"
                      :options="status_options"
                      @input="filterByStatus"
                    />
                  </div>
                  <div class="col-md-4 px-0">
                    <b-input-group>
                      <template #append>
                        <b-button
                          variant="success"
                          squared
                          size="sm"
                          @click="search"
                        >
                          Cari
                        </b-button>
                        <b-button
                          variant="danger"
                          squared
                          size="sm"
                          @click="reset"
                        >
                          Reset
                        </b-button>
                      </template>
                      <b-form-input
                        type="text"
                        v-model="filter.name"
                        placeholder="Cari Nama"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>

                <!-- timeseries filter -->
                <div class="row mt-3">
                  <div class="col-md-9" v-if="onShowPeriod">
                    <span class="d-none d-sm-block">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <b-button squared variant="info">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <!-- <template #append>
                        <b-button squared @click="pagination" variant="success"
                          >Cari</b-button
                        >
                        <b-button squared @click="reset" variant="danger"
                          >Reset</b-button
                        >
                      </template> -->
                      </b-input-group>
                    </span>
                    <span class="d-block d-sm-none">
                      <div class="row">
                        <div class="col-12 pb-1">
                          <b-input-group>
                            <b-form-input
                              v-model="filter.start_date"
                              type="text"
                              placeholder="Awal"
                              autocomplete="off"
                              readonly
                            ></b-form-input>
                            <b-input-group-append>
                              <b-form-datepicker
                                v-model="filter.start_date"
                                button-only
                                right
                                reset-button
                                locale="id"
                                aria-controls="example-input"
                              ></b-form-datepicker>
                            </b-input-group-append>
                            <!-- <template #append>
                            <b-button
                              squared
                              @click="pagination"
                              variant="success"
                              >Cari</b-button
                            >
                            <b-button
                              squared
                              @click="reset"
                              variant="danger"
                              >Reset</b-button
                            >
                          </template> -->
                          </b-input-group>
                        </div>
                        <div class="col-12 pt-0">
                          <b-input-group>
                            <b-button squared variant="info">s/d</b-button>
                            <b-form-input
                              v-model="filter.end_date"
                              type="text"
                              placeholder="Akhir"
                              autocomplete="off"
                              readonly
                            ></b-form-input>
                            <b-input-group-append>
                              <b-form-datepicker
                                v-model="filter.end_date"
                                button-only
                                right
                                reset-button
                                locale="id"
                                aria-controls="example-input"
                              ></b-form-datepicker>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <b-table
                  striped
                  hover
                  :items="items"
                  :fields="fields"
                  class="mt-3"
                  responsive
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'action' ? '15%' : '' }"
                    />
                  </template>

                  <template #cell(status)="data">
                    <b-badge
                      v-if="data.item.status_id == 1"
                      class="text-white"
                      style="cursor: pointer"
                      variant="warning"
                      @click="
                        currentUser.role_id == 4 ||
                        currentUser.role_id == 6 ||
                        statusAccess == true
                          ? showModalStatus(data.item)
                          : ''
                      "
                    >
                      {{ data.item.status_name }}
                    </b-badge>
                    <b-badge
                      v-if="data.item.status_id == 2"
                      class="btn-green text-white"
                      style="cursor: pointer"
                      @click="
                        currentUser.role_id == 4 ||
                        currentUser.role_id == 6 ||
                        statusAccess == true
                          ? showModalStatus(data.item)
                          : ''
                      "
                    >
                      {{ data.item.status_name }}
                    </b-badge>
                  </template>

                  <template #cell(action)="data">
                    <b-button
                      size="sm"
                      class="mr-1 btn-circle btn-outline-info"
                      v-b-tooltip.hover
                      title="Detail"
                      @click="
                        $router.push({
                          path:
                            '/pra-essay-seminar-registrations/detail/' +
                            data.item.id,
                        })
                      "
                      ><i class="fas fa-eye px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="mr-1 btn-circle btn-outline-success"
                      v-b-tooltip.hover
                      title="Edit"
                      @click="
                        $router.push({
                          path:
                            '/pra-essay-seminar-registrations/edit/' +
                            data.item.id,
                        })
                      "
                      v-if="data.item.student_id == currentUser.id"
                      ><i class="fas fa-edit px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="btn-circle btn-outline-danger"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteData(data.item.id)"
                      v-if="data.item.student_id == currentUser.id"
                      ><i class="fas fa-trash px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="btn-circle ml-1"
                      variant="outline-green"
                      v-b-tooltip.hover
                      title="Pendaftaran Sidang Skripsi"
                      @click="
                        $router.push({
                          path:
                            '/essay-exam-registrations/create/' +
                            data.item.id,
                        })
                      "
                      v-if="data.item.student_id == currentUser.id"
                      ><i class="fas fa-file px-0"></i
                    ></b-button>
                  </template>
                </b-table>

                <b-pagination
                  v-if="items.length != 0"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @page-click="pageOnClick"
                  class="mt-4"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Return Date -->
      <b-modal id="modal-status" @ok="changeStatus">
        <template #modal-title>Form Persetujuan Pendaftaran Sidang Seminar Pra Skripsi</template>
        <b-form-group
          id="input-group-registration-number"
          label="NIM:"
          label-for="input-registration-number"
        >
          <b-form-input
            id="input-registration-number"
            v-model="formStatus.registration_number"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-student"
          label="Mahasiswa:"
          label-for="input-student"
        >
          <b-form-input
            id="input-student"
            v-model="formStatus.name"
            disabled
          ></b-form-input>
        </b-form-group>
        <!-- <b-form-group
          id="input-group-teacher"
          label="Dosen:"
          label-for="input-teacher"
        >
          <b-form-input
            id="input-teacher"
            v-model="formStatus.teacher_name"
            disabled
          ></b-form-input>
        </b-form-group> -->
        <b-form-group
          id="input-group-project_title"
          label="Judul Proposal Skripsi:"
          label-for="input-project_title"
        >
          <b-form-input
            id="input-project_title"
            v-model="formStatus.essay_proposal_title"
            disabled
          ></b-form-input>
        </b-form-group>
        <label for="">Link</label>
        <ul class="ml-5">
          <li>
            <a target="_blank" :href="formStatus.essay_proposal_url">Proposal Skripsi</a>
          </li>
          <li>
            <a target="_blank" :href="formStatus.pra_essay_seminar_condition_url"
              >Syarat Seminar Pra Skripsi</a
            >
          </li>
        </ul>
        <b-form-group label="" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-2"
            v-model="formStatus.status_id"
            :aria-describedby="ariaDescribedby"
            name="radio-sub-component"
          >
            <template>
              <b-form-radio value="1">Baru</b-form-radio>
              <b-form-radio value="2">Disetujui</b-form-radio>
            </template>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group
          id="input-group-description"
          v-if="formStatus.status_id == 2"
        >
          <label for="input-description"
            >Catatan:
            <!-- <em class="text-muted">opsional</em> -->
          </label>
          <b-form-textarea
            id="input-description"
            v-model="formStatus.description"
            placeholder="Catatan"
            rows="4"
            max-rows="8"
            :disabled="currentUser.role_id == 4 ? false : true"
          ></b-form-textarea>
          <small class="text-danger">{{ errorStatus.description }}</small>
        </b-form-group>
        <b-form-group
          id="input-group-buy-date"
          v-if="formStatus.status_id == 2 && currentUser.role_id == 6"
        >
          <label for="input-buy-date">Tanggal Ujian: </label>
          <v-menu
            v-model="menuDate1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-form-input
                id="input-date"
                v-model="formStatus.exam_date"
                label="Tanggal Ujian:"
                readonly
                v-bind="attrs"
                v-on="on"
              ></b-form-input>
            </template>
            <v-date-picker
              v-model="formStatus.exam_date"
              @input="menuDate1 = false"
            ></v-date-picker>
          </v-menu>
          <small class="text-danger">{{ errorStatus.exam_date }}</small>
        </b-form-group>
        <template v-if="currentUser.role_id == 6">
        <b-form-group id="input-group-judge-chief">
          <label for="input-judge-chief">Ketua Sidang: </label>
          <treeselect
            v-model="formStatus.judge_chief"
            :multiple="false"
            placeholder="Pilih Ketua Sidang"
            :options="teachers"
          />
          <small class="text-danger">{{ errorStatus.judge_chief }}</small>
        </b-form-group>
        <b-form-group id="input-group-judge-1">
          <label for="input-judge-1">Penguji 1: </label>
          <treeselect
            v-model="formStatus.judge_1"
            :multiple="false"
            placeholder="Pilih Penguji 1"
            :options="teachers"
          />
          <small class="text-danger">{{ errorStatus.judge_1 }}</small>
        </b-form-group>
        <b-form-group id="input-group-judge-2">
          <label for="input-judge-2">Penguji 2: </label>
          <treeselect
            v-model="formStatus.judge_2"
            :multiple="false"
            placeholder="Pilih Penguji 2"
            :options="teachers"
          />
          <small class="text-danger">{{ errorStatus.judge_2 }}</small>
        </b-form-group>
        </template>
      </b-modal>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
import { getUser } from "@/core/services/jwt.service.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  data() {
    return {
      currentUser: getUser(),
      // filter
      filter: {
        name: "",
        start_date: "",
        end_date: "",
        status_id: "",
        // start_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        // end_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      },
      status_options: [
        { id: "", label: "Saring Status", isDisabled: true },
        { id: "1", label: "Baru" },
        { id: "2", label: "Disetujui" },
      ],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "exam_date_display",
          label: "Tanggal Ujian",
          sortable: true,
        },
        {
          key: "registration_number",
          label: "NIM",
          sortable: true,
        },
        {
          key: "name",
          label: "Mahasiswa",
          sortable: true,
        },
        // {
        //   key: "teacher_name",
        //   label: "Dosen",
        //   sortable: true,
        // },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      // formStatus
      formStatus: {
        id: "",
        status_id: "",
        judge_chief: "",
        judge_1: "",
        judge_2: "",
        exam_date: "",
      },
      errorStatus: {
        exam_date: "",
        judge_chief: "",
        judge_1: "",
        judge_2: "",
      },
      teachers: [],
      menuDate1: false,
      // access
      btnAccess: true,
      statusAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&status_id=${this.filter.status_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&name=${this.filter.name}`;
      if (this.currentUser.role_id == 1) {
        filterParams += `&teacher_id=${this.currentUser.id}`;
      } else if (this.currentUser.role_id == 2) {
        filterParams += `&student_id=${this.currentUser.id}`;
      }

      let response = await module.paginate(
        "api/pra-essay-seminar-registrations",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getTeacherOption() {
      let response = await module.setTreeSelect(
        "api/users",
        `?role_id_multiple=1,4,5,6&page_size=300`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teachers = response.data;
        this.teachers.unshift({
          label: "Pilih",
          id: 0,
          isDisabled: true,
        });
      }
    },

    filterByStatus(evt) {
      if (typeof evt == "undefined") {
        this.filter.status_id = "";
      }
      this.pagination();
    },
    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.filter.status_id = "";
      // set btn Active
      this.btnActive.days = true;
      this.btnActive.month = false;
      this.btnActive.lastMonth = false;
      this.btnActive.year = false;
      this.btnActive.period = false;

      this.filter.start_date = "";
      this.filter.end_date = "";

      this.pagination();
    },

    showModalStatus(data) {
      //   this.formStatus = data;
      this.formStatus.id = data.id;
      this.formStatus.name = data.name;
      this.formStatus.registration_number = data.registration_number;
      this.formStatus.essay_proposal_title = data.essay_proposal_title;
      this.formStatus.status_id = data.status_id;
      this.formStatus.teacher_name = data.teacher_name;
      this.formStatus.description = data.description;
      this.formStatus.essay_proposal_url = data.essay_proposal_url;
      this.formStatus.pra_essay_seminar_condition_url = data.pra_essay_seminar_condition_url;
      this.formStatus.judge_chief = data.judge_chief;
      this.formStatus.judge_1 = data.judge_1;
      this.formStatus.judge_2 = data.judge_2;

      if (data.exam_date == null) {
        this.formStatus.exam_date = "";
      } else {
        this.formStatus.exam_date = data.exam_date;
      }

      this.$bvModal.show("modal-status");
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      console.log(type);
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete(
        "api/pra-essay-seminar-registrations/" + id
      );
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    validationStatus(){
      console.log('validation status run')
      let status = true

      console.log('form status', this.formStatus)

      if(this.formStatus.exam_date == ""){
        this.errorStatus.exam_date = "Kolom ketua sidang wajib diisi"
        status = false
      }else{
        this.errorStatus.exam_date = ""
      }
      if(this.formStatus.judge_chief == "" || typeof this.formStatus.judge_chief == "undefined"){
        this.errorStatus.judge_chief = "Kolom ketua sidang wajib diisi"
        status = false
      }else{
        this.errorStatus.judge_chief = ""
      }
      if(this.formStatus.judge_1 == "" || typeof this.formStatus.judge_1 == "undefined"){
        this.errorStatus.judge_1 = "Kolom penguji 1 wajib diisi"
        status = false
      }else{
        this.errorStatus.judge_1 = ""
      }
      if(this.formStatus.judge_2 == "" || typeof this.formStatus.judge_2 == "undefined"){
        this.errorStatus.judge_2 = "Kolom penguji 2 wajib diisi"
        status = false
      }else{
        this.errorStatus.judge_2 = ""
      }
       return status
    },

    async changeStatus(bvModalEvt) {
      bvModalEvt.preventDefault();

      if(this.currentUser.role_id == 6){
        if(this.validationStatus() == false){
          throw new Error("Validation Error")
        }
      }

      let response = await module.submit(
        this.formStatus,
        `api/pra-essay-seminar-registrations/${this.formStatus.id}/change-status`
      );
      if (response.state == "error") {
        Swal.fire({
          title: "Gagal",
          text: "Gagal mengembalikan alat",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.errorStatus = validation.setValidationError(
          this.errorStatus,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-status");
        this.errorStatus.exam_date = ""
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1205") {
          this.btnAccess = true;
        }
        if (access_right[a] == "1206") {
          this.statusAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Pendaftaran Seminar Pra Skripsi",
        route: "/pra-essay-seminar-registrations",
      },
    ]);
    this.pagination();
    this.checkAccess();
    this.getTeacherOption();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>
